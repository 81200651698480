'use es6';

import get from 'transmute/get';
import getIn from 'transmute/getIn';
import setIn from 'transmute/setIn';
import { createSelector } from 'reselect';
import { getStagedFormState, getFormFields } from 'MeetingsLib/selectors/FormFieldValidationSelector';
import { getPublicAvailabilityInfoFetchStatus } from 'MeetingsLib/selectors/RequestStatusSelectors';
import { STARTED, SUCCEEDED } from 'MeetingsBase/constants/RequestStatusTypes';
import { getAdminDomain, getLinkEditParam } from 'MeetingsLib/utils/utils';
import { START_TIME_INTERVALS, START_TIME_INTERVAL_VALUES } from 'MeetingsBase/constants/enums';
import { MINUTES_TO_MILLIS } from 'MeetingsBase/constants/dates';
import { LINK_TYPES } from 'MeetingsBase/constants/enums';
import UserProfile from 'MeetingsBase/models/UserProfile';
const getForceOffline = get('forceOffline');
export const getPortalId = get('portalId');
const getOpenGates = getIn(['bookInfo', 'openGates']);
export const getIsUngatedForBrandingSizingMetadata = createSelector([getOpenGates], openGates => openGates.includes('Meetings:BrandingSizingMetadata'));
export const getIsUngatedForAvailabilityDaysLimit = createSelector([getOpenGates], openGates => openGates && openGates.includes('Meetings:AvailabilityDaysLimit') || false);
export const getIsUngatedForAddGuests = createSelector([getOpenGates], openGates => openGates && openGates.includes('Meetings:AddGuests') || false);
export const getIsUngatedForBlockFreeDomains = createSelector([getOpenGates], openGates => openGates && openGates.includes('Meetings:BlockFreeDomains') || false);
export const getIsUngatedForRotationsRoundRobin = createSelector([getOpenGates], openGates => openGates && openGates.includes('Meetings:RotationsRoundRobin') || false);
export const shouldFetchAvailability = createSelector([getPublicAvailabilityInfoFetchStatus], availabilityInfoFetchStatus => {
  return availabilityInfoFetchStatus !== STARTED && availabilityInfoFetchStatus !== SUCCEEDED;
});
const getDisplayInfoProp = props => get('displayInfo', props);
const getDisplayInfoState = getIn(['bookInfo', 'customParams', 'displayInfo']);
export const getDisplayInfo = (state, props) => {
  const propDisplayInfo = getDisplayInfoProp(props);
  const stateDisplayInfo = getDisplayInfoState(state);
  return propDisplayInfo || stateDisplayInfo;
};
const getWelcomeScreenInfoState = getIn(['bookInfo', 'customParams', 'welcomeScreenInfo']);
export const getWelcomeScreenInfo = state => {
  return getWelcomeScreenInfoState(state);
};
export const getLocaleFromSettings = getIn(['bookInfo', 'customParams', 'locale']);
export const getLanguageFromSettings = getIn(['bookInfo', 'customParams', 'language']);
const getBookingLifecycle = get('bookingLifecycle');
const getBookInfoState = get('bookInfo');
export const getUserTimezone = get('userTimezone');
export const getEvent = createSelector([getBookingLifecycle], bookingLifecycle => bookingLifecycle.event);
export const getMeetingLength = createSelector([getEvent], event => event.duration);
export const getBookInfo = createSelector([getBookInfoState, getDisplayInfo], (stateBookInfo, displayInfo) => {
  if (stateBookInfo) {
    return setIn(['customParams', 'displayInfo'], displayInfo, stateBookInfo);
  }
  return stateBookInfo;
});
export const getEditLinkURL = createSelector([getBookInfo, getPortalId], (bookInfo, portalId) => {
  const {
    linkId,
    linkType
  } = bookInfo;
  const linkEditParam = getLinkEditParam(linkType);
  const baseURL = getAdminDomain();
  return `${baseURL}/meetings/${portalId}/${linkEditParam}/${linkId}`;
});
export const getCustomParams = createSelector([getBookInfo], bookInfo => get('customParams', bookInfo));
export const getRecaptchaEnabled = createSelector([getCustomParams], customParams => get('recaptchaEnabled', customParams));
export const getLegalConsentEnabled = createSelector([getCustomParams], customParams => get('legalConsentEnabled', customParams));
export const getLegalConsentOptions = createSelector([getCustomParams], customParams => get('legalConsentOptions', customParams));
export const getIsLegitimateInterest = createSelector([getLegalConsentOptions], legalConsentOptions => get('isLegitimateInterest', legalConsentOptions));
export const getStartTimeIncrementMinutes = createSelector([getCustomParams], customParams => get('startTimeIncrementMinutes', customParams));
export const getStartTimeIntervalMilliseconds = createSelector([getStartTimeIncrementMinutes, getEvent], (startTimeIncrementMinutes, event) => {
  const startTimeMinuteValue = START_TIME_INTERVAL_VALUES[startTimeIncrementMinutes];
  return startTimeMinuteValue === START_TIME_INTERVALS.MEETING_DURATION ? event.duration : startTimeMinuteValue * MINUTES_TO_MILLIS;
});
export const getOffline = createSelector([getBookInfo, getForceOffline], (bookInfo, forceOffline) => forceOffline || bookInfo.offline);
export const getShouldAutoSubmit = getIn(['bookInfo', 'customParams', 'shouldAutoSubmit']);

/* TODO: We will need to include welcomeScreenInfo.showWelcomeScreen after old links are backfilled
  Issue: https://git.hubteam.com/hubspot/sales-meetings/issues/55
*/
export const shouldShowEmailForm = createSelector([getBookInfo, getStagedFormState, getShouldAutoSubmit], (bookInfo, stagedFormState, shouldAutoSubmit) => {
  return get('ownerPrioritized', bookInfo) && stagedFormState && (!stagedFormState.email || !shouldAutoSubmit);
});
export const shouldCenterPropertyForm = createSelector([getFormFields], formFields => formFields.isEmpty());
export const getPaymentLinkInfo = getIn(['bookInfo', 'customParams', 'paymentLinkInfo']);
export const getPaidMeetingsAvailableSelector = createSelector([getPaymentLinkInfo], paymentLinkInfo => !!paymentLinkInfo && !!paymentLinkInfo.checkoutUrl);
const getProfileFromEventDetails = (eventDetails, bookInfo) => {
  const organizerUserId = eventDetails.organizerUserId;
  const organizerUserProfile = bookInfo.allUsersBusyTimes.find(userBusyTimes => userBusyTimes.meetingsUser.userId === organizerUserId);
  return organizerUserProfile ? new UserProfile(organizerUserProfile.meetingsUser.userProfile) : null;
};
const getProfileFromProps = (state, props) => get('profile', props);
const getProfileFromState = get('profile');
const getRoundRobinProfile = getIn(['bookingLifecycle', 'postResponse', 'organizer']);
export const getRotationsRoundRobinProfile = createSelector([getBookInfo], bookInfo => {
  const {
    allUsersBusyTimes,
    linkType
  } = bookInfo;
  if (linkType !== LINK_TYPES.get('roundRobin')) return null;

  // We're not dealing with a rotation-based round robin if there's anything other than one meetings user
  if (allUsersBusyTimes.size !== 1) return null;
  return new UserProfile(allUsersBusyTimes.first().meetingsUser.userProfile);
});
const getEventDetails = get('eventDetails');
export const getUserProfile = createSelector([getProfileFromProps, getProfileFromState, getRoundRobinProfile, getEventDetails, getBookInfo], (propsProfile, stateProfile, roundRobinProfile, event, bookInfo) => {
  const eventProfile = event && bookInfo && getProfileFromEventDetails(event, bookInfo);
  return eventProfile || propsProfile || roundRobinProfile || stateProfile;
});
export const getGuestSettings = getIn(['bookInfo', 'customParams', 'guestSettings']);
export const getCanAddGuests = createSelector([getGuestSettings], guestSettings => guestSettings.canAddGuests);
export const getMaxGuestCount = createSelector([getGuestSettings], guestSettings => guestSettings.maxGuestCount);
export const getHasMoreLinkAvailability = createSelector([getBookInfo], bookInfo => {
  const hasMore = bookInfo && bookInfo.linkAvailability && bookInfo.linkAvailability.hasMore;
  return !!hasMore;
});
export const getHasAbsoluteZeroAvailability = createSelector([getBookInfo], bookInfo => bookInfo && bookInfo.linkAvailability && bookInfo.linkAvailability.hasZeroAvailability() || false);
export const getHasMoreLinkAvailabilityByMonthOffset = createSelector([getBookInfo], bookInfo => {
  const hasMoreByMonthOffset = bookInfo && bookInfo.linkAvailability && bookInfo.linkAvailability.hasMoreByMonthOffset;
  return hasMoreByMonthOffset;
});
export const getIsProcessingLinkAvailability = createSelector([getBookInfo], bookInfo => {
  const isProcessing = bookInfo && bookInfo.linkAvailability && bookInfo.linkAvailability.isProcessing;
  return !!isProcessing;
});
export const getHasCustomAvailability = createSelector([getCustomParams], customParams => {
  return !!customParams.customAvailabilityStartDate && !!customParams.customAvailabilityEndDate;
});
export const getCustomAvailabilityStartDate = createSelector([getCustomParams], customParams => {
  return customParams.customAvailabilityStartDate;
});
export const getPushbackTime = createSelector([getCustomParams], customParams => {
  return customParams.pushBackTime;
});