import { registerQuery } from 'data-fetching-client';
import { validateEmail, validateEmailV2 } from '../api/meetingsEmailAPI';
export const VALIDATE_EMAIL = registerQuery({
  fieldName: 'isValidEmail',
  args: ['email'],
  fetcher: ({
    email
  }) => validateEmail(email)
});
export const VALIDATE_EMAIL_V2 = registerQuery({
  fieldName: 'emailValidationV2',
  args: ['email', 'slug'],
  fetcher: ({
    email,
    slug
  }) => validateEmailV2({
    email,
    slug
  })
});