import { Record } from 'immutable';
const asyncValidationDefaults = {
  isValidationInProgress: false,
  validationResults: null,
  validationError: undefined
};
export class AsyncEmailValidation extends Record(asyncValidationDefaults) {
  static from(json) {
    return new AsyncEmailValidation(json);
  }
}
const defaults = {
  initialEmailValidationState: new AsyncEmailValidation(),
  stagedEmailValidationState: new AsyncEmailValidation()
};
export default class AsyncEmailValidationState extends Record(defaults) {
  static from(json) {
    return new AsyncEmailValidationState({
      initialEmailValidationState: AsyncEmailValidation.from(json.initialEmailValidationState),
      stagedEmailValidationState: AsyncEmailValidation.from(json.stagedEmailValidationState)
    });
  }
}