import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { EMAIL_ENDPOINT, EMAIL_ENDPOINT_V2 } from '../constants/endpoints';
import { hubletAwareApi } from '../utils/utils';
export function validateEmail(email) {
  return noAuthApiClient.get(`${EMAIL_ENDPOINT}/${email}/validate`, {
    withCredentials: false
  });
}
export function validateEmailV2({
  email,
  slug
}) {
  return noAuthApiClient.post(`${EMAIL_ENDPOINT_V2}`, {
    withCredentials: false,
    data: {
      email,
      slug
    },
    api: hubletAwareApi
  });
}