import { useEmailValidationV2 } from './useEmailValidation';
import { useAppSelector, useAppDispatch } from 'MeetingsLib/redux/reducers';

// @ts-expect-error untyped import
import { getInitialPrefillEmail } from 'MeetingsLib/selectors/PrefillSelectors';
// @ts-expect-error untyped import
import { getIsUngatedForBlockFreeDomains } from 'MeetingsLib/selectors/BookInfoSelectors';
// @ts-expect-error untyped import
import { getSlug } from 'MeetingsLib/selectors/RESTParamSelectors';
import { useEffect } from 'react';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
export const usePrefilledEmailValidation = () => {
  const dispatch = useAppDispatch();
  const email = useAppSelector(getInitialPrefillEmail);
  const slug = useAppSelector(getSlug);
  const isUngatedForBlockFreeDomains = useAppSelector(getIsUngatedForBlockFreeDomains);
  const {
    results,
    refetch,
    error,
    loading
  } = useEmailValidationV2({
    email,
    slug,
    shouldSkip: !isUngatedForBlockFreeDomains
  });
  useEffect(() => {
    if (loading) {
      dispatch({
        type: ActionTypes.ASYNC_INITIAL_EMAIL_VALIDATION_STARTED
      });
    }
    if (results) {
      dispatch({
        type: ActionTypes.ASYNC_INITIAL_EMAIL_VALIDATION_COMPLETED,
        payload: results
      });
    }
    if (error) {
      dispatch({
        type: ActionTypes.ASYNC_INITIAL_EMAIL_VALIDATION_FAILED,
        payload: error
      });
    }
  }, [dispatch, error, loading, results]);
  return {
    results,
    refetch,
    error,
    loading
  };
};