import { createSelector } from 'reselect';
import { getEvent, getShouldAutoSubmit, getCanAddGuests
// @ts-expect-error no type declarations for BookInfoSelectors
} from 'MeetingsLib/selectors/BookInfoSelectors';
import { getStagedFormFieldValidation, getInitialFormFieldValidation, getFormFieldValidation, getIsValidatingStagedEmail, getIsValidatingInitialEmail, getInitialEmailValidationResults, getStagedEmailValidationResults } from 'MeetingsLib/selectors/FormFieldValidationSelector';
// @ts-expect-error no type declarations for LegalConsentFormSelectors
import { getAllRequiredConsentCollected } from './LegalConsentFormSelectors';
import get from 'transmute/get';
const forcePropertyFormIfInitialFormComplete = get('forcePropertyForm');
const checkAllFormValidation = formStateValidation => {
  if (!formStateValidation) {
    return false;
  }
  const customFieldsInvalid = formStateValidation.get('customFormFields').filter(fieldValidation => {
    return Boolean(fieldValidation);
  }).size > 0;
  const isEmailInvalid = Boolean(formStateValidation.get('email'));
  const isFirstNameInvalid = Boolean(formStateValidation.get('firstName'));
  const isLastNameInvalid = Boolean(formStateValidation.get('lastName'));
  return !(customFieldsInvalid || isEmailInvalid || isFirstNameInvalid || isLastNameInvalid);
};

/**
 * The underlying initial form state in this selector is hydrated with the initial form fields (query params or backend prefills)
 * It is never updated after the app is initialized
 * It can now be false when the email is being validated asynchronously via block free domains
 */
export const canSubmitInitialPropertyForm = createSelector([getInitialFormFieldValidation, forcePropertyFormIfInitialFormComplete, getShouldAutoSubmit, getCanAddGuests, getIsValidatingInitialEmail, getInitialEmailValidationResults], (formFieldValidation, forcePropertyForm, shouldAutoSubmit, canAddGuests, isValidatingEmail, emailValidationResults) => {
  if (isValidatingEmail) {
    return false;
  } else if (emailValidationResults) {
    return (forcePropertyForm === false || shouldAutoSubmit && !canAddGuests) && checkAllFormValidation(formFieldValidation) && emailValidationResults.isValidEmail;
  }
  return (forcePropertyForm === false || shouldAutoSubmit && !canAddGuests) && checkAllFormValidation(formFieldValidation);
});

/**
 * The underlying stagedFormState in this selector is always hydrated with the staged form fields whether valid or not
 * It is hydrated in 3 ways:
 * 1. When the app is initialized, the stagedFormState is hydrated with the initial form fields (query params or backend prefills)
 * 2. When the user enters values in the form fields on the PropertyForm, the stagedFormState is updated with the new form fields
 * 3. It also sets the email field from the email form for contact prioritzation
 */
export const canSubmitStagedPropertyForm = createSelector([getStagedFormFieldValidation, getIsValidatingStagedEmail, getStagedEmailValidationResults], (formFieldValidation, isValidatingEmail, emailValidationResults) => {
  if (isValidatingEmail) {
    return false;
  } else if (emailValidationResults) {
    return checkAllFormValidation(formFieldValidation) && emailValidationResults.isValidEmail;
  }
  return checkAllFormValidation(formFieldValidation);
});

/**
 * The underlying formState represents what will submitted to the backend
 * It is hydrated in the following ways:
 * 1. When autosubmit is enabled, the formState is hydrated with the initial form fields (query params or backend prefills) if they are valid for submission on app init
 * 2. When the user submits the PropertyForm, the formState is updated with the stagedFormState fields
 * Due to async email validation brought by block free domains, it is possible for the formState to not be hydrated immediately after the app is initialized when autosubmit is enabled
 * Hence, when autosubmit is on, we set the formState with the now valid initialFormState fields when the user selects a date time
 * This will be initially null if initial form fields are not valid and user has not selected date time
 * because we will set it when user selects date time
 *
 */
export const canSubmitPropertyForm = createSelector([getFormFieldValidation, getIsValidatingStagedEmail, getStagedEmailValidationResults], (formFieldValidation, isValidatingEmail, emailValidationResults) => {
  if (isValidatingEmail) {
    return false;
  } else if (emailValidationResults) {
    return checkAllFormValidation(formFieldValidation) && emailValidationResults.isValidEmail;
  }
  return checkAllFormValidation(formFieldValidation);
});
export const canSubmitMeetingTime = createSelector([getEvent], event => Boolean(event.dateTime));
export const canSubmitBooking = createSelector([canSubmitMeetingTime, canSubmitPropertyForm, getAllRequiredConsentCollected], (meetingTimeValid, propertyFormValid, legalConsentValid) => meetingTimeValid && propertyFormValid && legalConsentValid);