import { combineReducers } from 'redux';
import emptyFunction from 'react-utils/emptyFunction';
import asyncEmailValidationState from 'MeetingsLib/redux/reducers/asyncEmailValidationState';
import networkErrors from 'MeetingsLib/redux/reducers/networkErrors';
import branding from 'MeetingsLib/redux/reducers/branding';
import bookingLifecycle from 'MeetingsLib/redux/reducers/bookingLifecycle';
import portalId from 'MeetingsLib/redux/reducers/portalId';
import portalAuth from 'MeetingsLib/redux/reducers/portalAuth';
import emailFormState from 'MeetingsLib/redux/reducers/emailFormState';
import initialFormState from 'MeetingsLib/redux/reducers/initialFormState';
import stagedFormState from 'MeetingsLib/redux/reducers/stagedFormState';
import formState from 'MeetingsLib/redux/reducers/formState';
import bookInfo from 'MeetingsLib/redux/reducers/bookInfo';
import eventDetails from 'MeetingsLib/redux/reducers/eventDetails';
import isSalesStarter from 'MeetingsLib/redux/reducers/isSalesStarter';
import calendarProvider from 'MeetingsLib/redux/reducers/calendarProvider';
import profile from 'MeetingsLib/redux/reducers/profile';
import userMessage from 'MeetingsLib/redux/reducers/userMessage';
import userTimezone from 'MeetingsLib/redux/reducers/userTimezone';
import requestStatus from 'MeetingsLib/redux/reducers/requestStatus';
import activeLocale from 'MeetingsLib/redux/reducers/activeLocale';
import companyName from 'MeetingsLib/redux/reducers/companyName';
import stagedLegalConsentResponses from 'MeetingsLib/redux/reducers/stagedLegalConsentResponses';
import legalConsentResponses from 'MeetingsLib/redux/reducers/legalConsentResponses';
import troubleshooting from 'MeetingsLib/redux/reducers/troubleshooting';
import legitimateInterestConfirmed from 'MeetingsLib/redux/reducers/legitimateInterestConfirmed';
import recaptcha from 'MeetingsLib/redux/reducers/recaptcha';
import paidMeetingStatus from 'MeetingsLib/redux/reducers/paidMeetingStatus';
import guestEmails from 'MeetingsLib/redux/reducers/guestEmails';
export default combineReducers({
  asyncEmailValidationState,
  requestStatus,
  bookingLifecycle,
  networkErrors,
  branding,
  portalId,
  portalAuth,
  emailFormState,
  initialFormState,
  stagedFormState,
  formState,
  bookInfo,
  eventDetails,
  isSalesStarter,
  calendarProvider,
  profile,
  userMessage,
  userTimezone,
  activeLocale,
  companyName,
  stagedLegalConsentResponses,
  legalConsentResponses,
  troubleshooting,
  legitimateInterestConfirmed,
  recaptcha,
  paidMeetingStatus,
  guestEmails,
  trackingParams: (state = null) => state,
  abTestingParams: (state = null) => state,
  prefilledCustomFormFields: (state = null) => state,
  userSlug: (state = null) => state,
  embedded: (state = false) => state,
  isRescheduling: (state = false) => state,
  meetingChangeId: (state = null) => state,
  forceOffline: (state = false) => state,
  forcePropertyForm: (state = null) => state,
  queryParams: (state = {}) => state,
  currentTime: (state = null) => state,
  initTrackingForMeetings: (state = null) => state,
  onSuccess: (state = emptyFunction) => state,
  onFailure: (state = emptyFunction) => state,
  forceMobile: (state = null) => state,
  parentPageHSAnalyticsInfo: (state = null) => state,
  allowTroubleshootMode: (state = null) => state,
  allowRedirect: (state = null) => state,
  disablePrefill: (state = false) => state
});