import { Record, List } from 'immutable';
export const MAX_BLOCKED_DOMAINS = 25;
const defaults = {
  blockFreeEmailDomains: false,
  blockedSpecificDomains: List()
};
export default class EmailValidationSettings extends Record(defaults) {
  static from(json) {
    if (!json) {
      // this is going to be undefined for the PublicAvailabiltyInfo model
      return new EmailValidationSettings();
    }
    return new EmailValidationSettings({
      blockFreeEmailDomains: json.blockFreeEmailDomains,
      blockedSpecificDomains: List(json.blockedSpecificDomains)
    });
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
  convertToJSON() {
    return {
      blockFreeEmailDomains: this.blockFreeEmailDomains,
      blockedSpecificDomains: this.blockedSpecificDomains.toArray()
    };
  }
  equals(other) {
    return this.blockFreeEmailDomains === other.blockFreeEmailDomains && this.blockedSpecificDomains.sort().equals(other.blockedSpecificDomains.sort());
  }
}