// @ts-expect-error untyped import
import { handleActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
import AsyncEmailValidationState, { AsyncEmailValidation } from 'MeetingsLib/models/AsyncEmailValidationState';
const initialState = new AsyncEmailValidationState();
export default handleActions({
  [ActionTypes.ASYNC_INITIAL_EMAIL_VALIDATION_STARTED](state) {
    // update stagedEmailValidationState as well because it starts off the same as initialFormState
    return state.withMutations(mutableState => mutableState.setIn(['initialEmailValidationState', 'isValidationInProgress'], true).setIn(['stagedEmailValidationState', 'isValidationInProgress'], true).setIn(['initialEmailValidationState', 'validationError'], undefined).setIn(['stagedEmailValidationState', 'validationError'], undefined));
  },
  [ActionTypes.ASYNC_INITIAL_EMAIL_VALIDATION_COMPLETED](state, action) {
    // update stagedEmailValidationState as well because it starts off the same as initialFormState
    return state.withMutations(mutableState => mutableState.setIn(['initialEmailValidationState', 'isValidationInProgress'], false).setIn(['initialEmailValidationState', 'validationResults'], action.payload).setIn(['initialEmailValidationState', 'validationError'], undefined).setIn(['stagedEmailValidationState', 'isValidationInProgress'], false).setIn(['stagedEmailValidationState', 'validationResults'], action.payload).setIn(['stagedEmailValidationState', 'validationError'], undefined));
  },
  [ActionTypes.ASYNC_INITIAL_EMAIL_VALIDATION_FAILED](state, action) {
    return state.set('initialEmailValidationState', new AsyncEmailValidation({
      validationError: action.payload
    }));
  },
  [ActionTypes.ASYNC_STAGED_EMAIL_VALIDATION_STARTED](state) {
    return state.set('stagedEmailValidationState', new AsyncEmailValidation({
      isValidationInProgress: true
    }));
  },
  [ActionTypes.ASYNC_STAGED_EMAIL_VALIDATION_COMPLETED](state, action) {
    return state.withMutations(mutableState => mutableState.setIn(['stagedEmailValidationState', 'isValidationInProgress'], false).setIn(['stagedEmailValidationState', 'validationResults'], action.payload).setIn(['stagedEmailValidationState', 'validationError'], undefined));
  },
  [ActionTypes.ASYNC_STAGED_EMAIL_VALIDATION_FAILED](state, action) {
    return state.set('stagedEmailValidationState', new AsyncEmailValidation({
      validationError: action.payload
    }));
  }
}, initialState);