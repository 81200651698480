import { useQuery } from 'data-fetching-client';
import { VALIDATE_EMAIL, VALIDATE_EMAIL_V2 } from '../query/emailValidationQuery';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Patt... Remove this comment to see the full error message
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
export const useEmailValidation = ({
  client,
  shouldSkip,
  email
}) => {
  const emailValidationQueryOptions = {
    skip: shouldSkip || !email,
    fetchPolicy: 'cache-first',
    variables: {
      email
    }
  };
  const {
    data,
    error,
    refetch,
    loading
  } = useQuery(VALIDATE_EMAIL, client ? Object.assign({
    client
  }, emailValidationQueryOptions) : emailValidationQueryOptions);
  const isValid = data && data.isValidEmail || false;
  return {
    isValid,
    reValidateEmail: refetch,
    error,
    loading
  };
};
export const useEmailValidationV2 = ({
  client,
  email,
  slug,
  shouldSkip = false
}) => {
  const emailValidationQueryOptions = {
    skip: shouldSkip || !EmailAddressPattern.test(email) || !slug,
    fetchPolicy: 'cache-first',
    variables: {
      email,
      slug
    }
  };
  const {
    data,
    error,
    refetch,
    loading,
    previousData
  } = useQuery(VALIDATE_EMAIL_V2, client ? Object.assign({
    client
  }, emailValidationQueryOptions) : emailValidationQueryOptions);
  const results = data && data.emailValidationV2;
  const previousResults = previousData && previousData.emailValidationV2;
  return {
    results,
    refetch,
    error,
    loading,
    previousResults
  };
};