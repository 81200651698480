import I18n from 'I18n';
import { Map as ImmutableMap } from 'immutable';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { createSelector } from 'reselect';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Patt... Remove this comment to see the full error message
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
import { FORM_FIELD_TYPES } from 'MeetingsBase/constants/enums';
import { isEmpty, isWhitespace } from 'MeetingsLib/utils/utils';
const MAX_NAME_LENGTH = 45;

// Based on: https://git.hubteam.com/HubSpot/FormsNext/blob/40962c16adbeebd922c7b610a9a4898f32dbd7e7/static/coffee/components/inputs/Phone.cjsx
const PHONE_NUMBER_REGEX = new RegExp(/^\+?(((?:\(x?\d+\))|(?:x?\d+))[-.]?)+$/);
function validateEmail(email) {
  if (email && EmailAddressPattern.test(email)) {
    return false;
  }
  return I18n.text('prebook.details.errorHelper.email');
}
function validateFirstAndLastName(fieldValue) {
  const isEmptyString = fieldValue === null || fieldValue === undefined || isWhitespace(fieldValue) || isEmpty(fieldValue);
  if (isEmptyString) {
    return I18n.text('prebook.details.errorHelper.required');
  }
  if (fieldValue.length > MAX_NAME_LENGTH) {
    return I18n.text('prebook.details.errorHelper.tooLong');
  }
  return false;
}
function validateCustomFormFields(customFormFields, formFields) {
  // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message
  if (!formFields || !formFields.size > 0) {
    // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    return new ImmutableMap();
  }
  const customFormFieldValidation = customFormFields.reduce((acc, fieldValue, fieldName) => {
    const field = formFields.find(formField => {
      return formField.name === fieldName;
    });
    if (!field) {
      return acc.set(fieldName, false);
    }
    let hasValue = fieldValue !== null && fieldValue !== undefined;
    if (field.fieldType === FORM_FIELD_TYPES.CHECKBOX || field.fieldType === FORM_FIELD_TYPES.ENUMERATION) {
      hasValue = hasValue && fieldValue.length > 0;
    }
    if (field.type === FORM_FIELD_TYPES.TEXT) {
      hasValue = hasValue && fieldValue.trim() !== '';
    }
    if (field.fieldType === FORM_FIELD_TYPES.BOOLEAN_CHECKBOX) {
      hasValue = hasValue && fieldValue !== false;
    }
    if (!hasValue && field.isRequired) {
      return acc.set(fieldName, I18n.text('prebook.details.errorHelper.required'));
    }
    if (hasValue && field.fieldType === FORM_FIELD_TYPES.PHONE_NUMBER && typeof fieldValue === 'string') {
      const fieldValueWithoutSpaces = fieldValue.replace(/\s/g, '');
      const isValidPhoneNumber = PHONE_NUMBER_REGEX.test(fieldValueWithoutSpaces);
      if (!isValidPhoneNumber) {
        return acc.set(fieldName, I18n.text('prebook.details.errorHelper.phoneInvalidCharacters'));
      }
    }
    return acc.set(fieldName, false);
  }, ImmutableMap());
  return customFormFieldValidation;
}

// @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
const VALIDATOR_MAP = new ImmutableMap({
  email: validateEmail,
  firstName: validateFirstAndLastName,
  lastName: validateFirstAndLastName,
  customFormFields: validateCustomFormFields
});
export const getInitialFormState = get('initialFormState');
export const getStagedFormState = get('stagedFormState');
export const getFormState = get('formState');
export const getCustomFormFields = createSelector(
// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
[getStagedFormState],
// @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
stagedFormState => stagedFormState.customFormFields);
export const getFormFields = getIn(['bookInfo', 'customParams', 'formFields']);
const getValidation = (validatorMap, formStateGetter) => {
  return createSelector([formStateGetter, getFormFields], (formState, formFields) => {
    if (!formState) {
      return false;
    }
    return validatorMap.reduce((validationErrors, validationFunction, fieldName) => {
      const formValue = formState.get(fieldName);
      return validationErrors.set(fieldName, validationFunction(formValue, formFields));
    },
    // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    new ImmutableMap());
  });
};
export const getStagedFormFieldValidation = getValidation(VALIDATOR_MAP, getStagedFormState);
export const getFormFieldValidation = getValidation(VALIDATOR_MAP, getFormState);
export const getInitialFormFieldValidation = getValidation(VALIDATOR_MAP, getInitialFormState);
export const getAsyncEmailValidationState = state => get('asyncEmailValidationState')(state);
export const getIsValidatingInitialEmail = createSelector([getAsyncEmailValidationState], asyncEmailValidationState => {
  return asyncEmailValidationState.initialEmailValidationState.isValidationInProgress;
});
export const getInitialEmailValidationResults = createSelector([getAsyncEmailValidationState], asyncEmailValidationState => {
  return asyncEmailValidationState.initialEmailValidationState.validationResults;
});
export const getIsValidatingStagedEmail = createSelector([getAsyncEmailValidationState], asyncEmailValidationState => {
  return asyncEmailValidationState.stagedEmailValidationState.isValidationInProgress;
});
export const getStagedEmailValidationResults = createSelector([getAsyncEmailValidationState], asyncEmailValidationState => {
  return asyncEmailValidationState.stagedEmailValidationState.validationResults;
});
export const getStagedEmailValidationError = createSelector([getAsyncEmailValidationState], asyncEmailValidationState => {
  return asyncEmailValidationState.stagedEmailValidationState.validationError;
});