export const SET_EMAIL_FORM = 'SET_EMAIL_FORM';
export const RESET_EMAIL_FORM = 'RESET_EMAIL_FORM';
export const SET_FORM_FIELD = 'SET_FORM_FIELD';
export const SET_FORM_STATE = 'SET_FORM_STATE';
export const RESET_FORM_STATE = 'RESET_FORM_STATE';
export const ADD_GUEST_EMAIL = 'ADD_GUEST_EMAIL';
export const REMOVE_GUEST_EMAIL = 'REMOVE_GUEST_EMAIL';
export const SET_CONSENT_RESPONSES = 'SET_CONSENT_RESPONSES';
export const RESET_CONSENT_RESPONSES = 'RESET_CONSENT_RESPONSES';
export const LOG_ERROR_PAGE_SHOWN = 'LOG_ERROR_PAGE_SHOWN';
export const SHOW_USER_MESSAGE = 'SHOW_USER_MESSAGE';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const SET_EVENT_DATE = 'SET_EVENT_DATE';
export const SET_EVENT_DATE_TIME = 'SET_EVENT_DATE_TIME';
export const SET_EVENT_DURATION = 'SET_EVENT_DURATION';
export const SET_GIVEN_CONSENT = 'SET_GIVEN_CONSENT';
export const LOG_MEETING_CREATE = 'LOG_MEETING_CREATE';
export const BOOKING_TIME_CONFLICT = 'BOOKING_TIME_CONFLICT';
export const COMPANY_NAME_FETCH_STARTED = 'COMPANY_NAME_FETCH_STARTED';
export const COMPANY_NAME_FETCH_SUCCEEDED = 'COMPANY_NAME_FETCH_SUCCEEDED';
export const COMPANY_NAME_FETCH_FAILED = 'COMPANY_NAME_FETCH_FAILED';
export const MEETINGS_USER_FETCH_STARTED = 'MEETINGS_USER_FETCH_STARTED';
export const MEETINGS_USER_FETCH_SUCCEEDED = 'MEETINGS_USER_FETCH_SUCCEEDED';
export const MEETINGS_USER_FETCH_FAILED = 'MEETINGS_USER_FETCH_FAILED';
export const SET_ACTIVE_LOCALE = 'SET_ACTIVE_LOCALE';
export const SET_ACTIVE_LOCALE_FINISHED = 'SET_ACTIVE_LOCALE_FINISHED';
export const PUBLIC_AVAILABILITY_INFO_FETCH_STARTED = 'PUBLIC_AVAILABILITY_INFO_FETCH_STARTED';
export const PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED = 'PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED';
export const PUBLIC_AVAILABILITY_INFO_FETCH_FAILED = 'PUBLIC_AVAILABILITY_INFO_FETCH_FAILED';
export const PUBLIC_AVAILABILITY_INFO_OWNER_FETCH_STARTED = 'PUBLIC_AVAILABILITY_INFO_OWNER_FETCH_STARTED';
export const PUBLIC_AVAILABILITY_INFO_OWNER_FETCH_SUCCEEDED = 'PUBLIC_AVAILABILITY_INFO_OWNER_FETCH_SUCCEEDED';
export const PUBLIC_AVAILABILITY_INFO_OWNER_FETCH_FAILED = 'PUBLIC_AVAILABILITY_INFO_OWNER_FETCH_FAILED';
export const MEETING_CREATE_STARTED = 'MEETING_CREATE_STARTED';
export const MEETING_CREATE_SUCCEEDED = 'MEETING_CREATE_SUCCEEDED';
export const MEETING_CREATE_RESET = 'MEETING_CREATE_RESET';
export const MEETING_CREATE_FAILED = 'MEETING_CREATE_FAILED';
export const MEETING_CREATE_UNINITIALIZED = 'MEETING_CREATE_UNINITIALIZED';
export const TOGGLE_TROUBLESHOOT_MODE = 'TOGGLE_TROUBLESHOOT_MODE';
export const REQUEST_TROUBLESHOOT_TIMES = 'REQUEST_TROUBLESHOOT_TIMES';
export const RECEIVE_TROUBLESHOOT_TIMES = 'RECEIVE_TROUBLESHOOT_TIMES';
export const REQUEST_TROUBLESHOOT_TIMES_REJECTED = 'REQUEST_TROUBLESHOOT_TIMES_REJECTED';
export const UPDATE_TROUBLESHOOT_TIMES = 'UPDATE_TROUBLESHOOT_TIMES';
export const SET_LEGITIMATE_INTEREST_CONFIRMED = 'SET_LEGITIMATE_INTEREST_CONFIRMED';
export const PORTAL_AUTH_SUCCEEDED = 'PORTAL_AUTH_SUCCEEDED';
export const UPDATE_RECAPTCHA_WIDGET_ID = 'UPDATE_RECAPTCHA_WIDGET_ID';
export const UPDATE_RECAPTCHA_TOKEN = 'UPDATE_RECAPTCHA_TOKEN';
export const FETCH_EVENT_DETAILS_SUCCEEDED = 'FETCH_EVENT_DETAILS_SUCCEEDED';
export const FETCH_EVENT_DETAILS_FAILED = 'FETCH_EVENT_DETAILS_FAILED';
export const MEETING_CANCEL_STARTED = 'MEETING_CANCEL_STARTED';
export const MEETING_CANCEL_SUCCEEDED = 'MEETING_CANCEL_SUCCEEDED';
export const MEETING_CANCEL_FAILED = 'MEETING_CANCEL_FAILED';
export const MEETING_RESCHEDULE_STARTED = 'MEETING_RESCHEDULE_STARTED';
export const MEETING_RESCHEDULE_SUCCEEDED = 'MEETING_RESCHEDULE_SUCCEEDED';
export const MEETING_RESCHEDULE_FAILED = 'MEETING_RESCHEDULE_FAILED';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const SET_PAYMENT_SESSION_ID = 'SET_PAYMENT_SESSION_ID';
export const SET_PAYMENT_FINAL_PRICE = 'SET_PAYMENT_FINAL_PRICE';
export const PAID_MEETING_BOOKED_SUCCESSFULLY = 'PAID_MEETING_BOOKED_SUCCESSFULLY';
export const PAID_MEETING_BOOKING_TIME_UNAVAILABLE = 'PAID_MEETING_BOOKING_TIME_UNAVAILABLE';
export const PAID_MEETING_BOOKING_FAILURE = 'PAID_MEETING_BOOKING_FAILURE';
export const PAID_MEETING_BOOKING_COMPLETED_LEGAL_CONSENT_FORM = 'PAID_MEETING_BOOKING_COMPLETED_LEGAL_CONSENT_FORM';
export const PAID_MEETING_BOOKING_COMPLETED_PROPERTY_FORM = 'PAID_MEETING_BOOKING_COMPLETED_PROPERTY_FORM';
export const PAID_MEETING_BOOKING_SELECTED_TIME = 'PAID_MEETING_BOOKING_SELECTED_TIME';
export const PAID_MEETING_BOOKING_PAYMENT_SUCCESSFUL = 'PAID_MEETING_BOOKING_PAYMENT_SUCCESSFUL';
export const PAID_MEETING_BOOKING_PAYMENT_PROCESSING = 'PAID_MEETING_BOOKING_PAYMENT_PROCESSING';
export const PAID_MEETING_BOOKING_PAYMENT_FAILED = 'PAID_MEETING_BOOKING_PAYMENT_FAILED';
export const UPDATE_LINK_AVAILABILITY = 'UPDATE_LINK_AVAILABILITY';
export const UPDATE_USER_BUSY_TIMES = 'UPDATE_USER_BUSY_TIMES';
export const RESET_BOOKING_TIME_CONFLICT_STATUS = 'RESET_BOOKING_TIME_CONFLICT_STATUS';
export const ASYNC_INITIAL_EMAIL_VALIDATION_COMPLETED = 'ASYNC_INITIAL_EMAIL_VALIDATION_COMPLETED';
export const ASYNC_INITIAL_EMAIL_VALIDATION_STARTED = 'ASYNC_INITIAL_EMAIL_VALIDATION_STARTED';
export const ASYNC_INITIAL_EMAIL_VALIDATION_FAILED = 'ASYNC_INITIAL_EMAIL_VALIDATION_FAILED';
export const ASYNC_STAGED_EMAIL_VALIDATION_COMPLETED = 'ASYNC_STAGED_EMAIL_VALIDATION_COMPLETED';
export const ASYNC_STAGED_EMAIL_VALIDATION_STARTED = 'ASYNC_STAGED_EMAIL_VALIDATION_STARTED';
export const ASYNC_STAGED_EMAIL_VALIDATION_FAILED = 'ASYNC_STAGED_EMAIL_VALIDATION_FAILED';